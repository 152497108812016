import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import StepIndicator from './StepIndicator';
import CreateEventStep from './CreateEventStep';
import { useHistory, useParams } from 'react-router-dom';
import styles from './CreateEventPage.module.scss';
import { CREATE_EVENT_PAGE, EDIT_EVENT_PAGE } from '../../routes';
import {
  deleteRespondentEventSaga,
  respondentEventSaga,
  setFinishedSteps,
} from '../../store/respondentEvent/actions';
import {
  currentRespondentEventIdSelector,
  eventStepsSelector,
  finishedStepsSelector,
} from '../../store/respondentEvent/selectors';
import VideoRecorderComponent from '../../components/CreateEvent/Video/VideoRecorderComponent';
import CFQuestionDialog from '../../sharedComponents/Dialog/CFQuestionDialog';
import { openLink } from '../../helpers/LinkHelper';
import AudioRecorderComponent from '../../components/CreateEvent/Audio/AudioRecorderComponent';
import { setSnackbar } from '../../store/snackbar/actions';
import { $t } from '../../translations';
import { journeySelector } from '../../store/journey/selectors';
import respondentEventService from '../../services/RespondentEventService';
import { makeSelectUser } from '../../store/auth/selectors';

const CreateEventPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { respondentEventId, stepId } = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const [events, setEvents] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEventOtherValue, setSelectedEventOtherValue] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [details, setDetails] = useState('');
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [questionDialogData, setQuestionDialogData] = useState({
    title: '',
    isVisible: false,
    action: '',
  });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const eventSteps = useSelector(eventStepsSelector());
  const currentRespondentEventId = useSelector(
    currentRespondentEventIdSelector()
  );
  const journey = useSelector(journeySelector());
  const finishedSteps = useSelector(finishedStepsSelector());
  const respondent = useSelector(makeSelectUser());

  const goStepBack = () => {
    if (respondentEventId) {
      history.push(
        EDIT_EVENT_PAGE.replace(
          ':respondentEventId',
          respondentEventId
        ).replace(':stepId', (activeStep - 1).toString())
      );
    } else {
      history.push(
        CREATE_EVENT_PAGE.replace(':stepId', (activeStep - 1).toString())
      );
    }
  };

  const onSubmit = () => {
    let errors = [];
    if (selectedMonth === '' && activeStep === 1) {
      errors.push('month');
    }
    if (selectedYear === '' && activeStep === 1) {
      errors.push('year');
    }
    if (selectedEvents.length === 0) {
      errors.push('event');
    }

    const eventWithInput = selectedEvents.find(
      (selectedEvent) => selectedEvent.event.value_required
    );
    if (eventWithInput && selectedEventOtherValue === '') {
      errors.push('otherValue');
    }

    setValidationErrors(errors);

    if (errors.length === 0) {
      setIsSubmitButtonDisabled(true);
      if (activeStep < journey?.stepJourneys.length) {
        setActiveStep((prevState) => prevState + 1);
      }

      if (!finishedSteps.includes(activeStep)) {
        dispatch(setFinishedSteps(activeStep));
      }
      const data = {
        activeStep,
        selectedEvents: selectedEvents,
        ...(selectedEvents.find(
          (selectedEvent) => selectedEvent.event.value_required
        ) && {
          other_value: selectedEventOtherValue,
        }),
        step_id: journey?.stepJourneys[activeStep - 1].step_id,
        details: details,
        video: video,
        audio: audio,
        ...(activeStep === 1 && { month: selectedMonth, year: selectedYear }),
        lastStep: activeStep === journey?.stepJourneys.length,
        respondentEventId: respondentEventId,
      };
      dispatch(respondentEventSaga(data));
    }
  };

  const handleSelectedEvents = (event) => {
    removeValidationError('event');
    if (
      selectedEvents?.find((selectedEvent) => selectedEvent.id === event.id)
    ) {
      setSelectedEvents((prevState) =>
        prevState.filter((selectedEvent) => selectedEvent.id !== event.id)
      );
    } else {
      if (activeStep === 1 && selectedEvents.length > 0) {
        return dispatch(
          setSnackbar({
            open: true,
            severity: 'error',
            message: $t('snackbar.message.respondent-steps.maximum-one-event'),
          })
        );
      }

      if (activeStep > 1 && selectedEvents.length > 2) {
        return dispatch(
          setSnackbar({
            open: true,
            severity: 'error',
            message: $t(
              'snackbar.message.respondent-steps.maximum-three-events'
            ),
          })
        );
      }

      setSelectedEvents((prevState) => [...prevState, event]);
    }
  };

  const onAudioRecordingComplete = async ({ audio, status, discard }) => {
    if (status === 'paused' && !discard) {
      const blob = await fetch(audio).then((r) => r.blob());
      setAudio(blob);
      setCurrentAudioUrl(audio);
      dispatch(
        setSnackbar({
          open: true,
          severity: 'success',
          message: $t('snackbar.message.audio.recorded'),
        })
      );
    }
  };

  const handleOnVideoRecordingComplete = (video) => {
    setVideo(video);
    const url = URL.createObjectURL(video);
    setCurrentVideoUrl(url);
    dispatch(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.video.recorded'),
      })
    );
    setIsVideoRecording(false);
  };

  const playAudio = () => {
    if (eventSteps.find((event) => event.id === parseInt(stepId))?.audio) {
      openLink(eventSteps.find((event) => event.id === parseInt(stepId)).audio);
    } else {
      openLink(currentAudioUrl);
    }
  };

  const playVideo = () => {
    if (eventSteps.find((event) => event.id === parseInt(stepId))?.video) {
      openLink(eventSteps.find((event) => event.id === parseInt(stepId)).video);
    } else {
      openLink(currentVideoUrl);
    }
  };

  const removeAudio = () => {
    setAudio('');
    setQuestionDialogData((prevState) => ({ ...prevState, isVisible: false }));
    dispatch(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.audio.removed'),
      })
    );
  };

  const removeVideo = () => {
    setVideo('');
    setQuestionDialogData((prevState) => ({ ...prevState, isVisible: false }));
    dispatch(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.video.removed'),
      })
    );
  };

  const discardEvent = () => {
    dispatch(deleteRespondentEventSaga(currentRespondentEventId));
    setQuestionDialogData((prevState) => ({ ...prevState, isVisible: false }));
    resetRespondentEventValues();
  };

  const questionDialogConfirmHandler = () => {
    switch (questionDialogData.action) {
      case 'discard-event':
        discardEvent();
        break;
      case 'remove-audio':
        removeAudio();
        break;
      case 'remove-video':
        removeVideo();
        break;
      default:
        setQuestionDialogData((prevState) => ({
          ...prevState,
          isVisible: false,
        }));
    }
  };

  const resetRespondentEventValues = () => {
    setSelectedMonth('');
    setSelectedYear('');
    setDetails('');
    setSelectedEvents([]);
    setSelectedEventOtherValue('');
    setAudio('');
    setVideo('');
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && respondent?.project?.status !== 'paused') {
      respondentEventService
        .getActiveRespondentEvent()
        .then((event) => {
          if (event) {
            if (stepId > event.respondentSteps?.length) {
              history.push(
                CREATE_EVENT_PAGE.replace(
                  ':stepId',
                  (event.respondentSteps?.length + 1).toString()
                )
              );
              setActiveStep(parseInt(event.respondentSteps.length + 1));
            } else {
              setActiveStep(parseInt(stepId));
            }
          } else {
            if (!respondentEventId) {
              history.push(
                CREATE_EVENT_PAGE.replace(':stepId', (1).toString())
              );
              setActiveStep(1);
            } else {
              setActiveStep(parseInt(stepId));
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    }
    return () => (mounted = false);
  }, [stepId]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setEvents(
        journey?.stepJourneys[parseInt(activeStep) - 1]?.stepJourneyEvents
      );
    }
    return () => (mounted = false);
  }, [journey, activeStep]);

  useEffect(() => {
    const event = eventSteps.find((event) => event.id === activeStep);

    if (event) {
      setSelectedMonth(event?.month || '');
      setSelectedYear(event?.year || '');
      setSelectedEvents(event?.selectedEvents || []);
      setDetails(event?.details || '');
      setAudio(event?.audio || '');
      setVideo(event?.video || '');
    } else {
      setSelectedMonth('');
      setSelectedYear('');
      setSelectedEvents([]);
      setDetails('');
      setAudio(null);
      setVideo(null);
    }
  }, [activeStep, eventSteps]);

  useEffect(() => {
    const array = [selectedEvents.length === 0];

    if (activeStep === 1) {
      array.push(!selectedMonth, !selectedYear);
    }

    if (
      selectedEvents.find((selectedEvent) => selectedEvent.event.value_required)
    ) {
      array.push(!selectedEventOtherValue);
    }

    setIsSubmitButtonDisabled(array.some((value) => value === true));
  }, [
    activeStep,
    selectedEvents,
    selectedEventOtherValue,
    selectedMonth,
    selectedYear,
  ]);

  const removeValidationError = (value) => {
    setValidationErrors(validationErrors.filter((item) => item !== value));
  };

  return (
    <Container className={styles.container}>
      <StepIndicator
        steps={journey?.stepJourneys}
        activeStep={activeStep}
        finishedSteps={finishedSteps}
      />
      {journey?.stepJourneys?.length > 0 && events && (
        <CreateEventStep
          validationErrors={validationErrors}
          events={events}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedMonth={(value) => {
            setSelectedMonth(value);
            removeValidationError('month');
          }}
          setSelectedYear={(value) => {
            setSelectedYear(value);
            removeValidationError('year');
          }}
          selectedEvents={selectedEvents}
          setSelectedEvents={handleSelectedEvents}
          selectedEventOtherValue={selectedEventOtherValue}
          setSelectedEventOtherValue={(value) => {
            setSelectedEventOtherValue(value);
            removeValidationError('otherValue');
          }}
          steps={journey?.stepJourneys}
          eventSteps={eventSteps}
          activeStep={activeStep}
          finishedSteps={finishedSteps}
          onSubmit={onSubmit}
          details={details}
          onChangeDetails={(value) => setDetails(value)}
          activeStepEvent={eventSteps?.find((event) => event.id === activeStep)}
          onClickBack={goStepBack}
          video={video}
          audio={audio}
          recordAudio={() => setIsAudioRecording(true)}
          recordVideo={() => setIsVideoRecording(true)}
          removeAudio={() =>
            setQuestionDialogData({
              title: $t('questionDialog.title.audio-delete'),
              isVisible: true,
              action: 'remove-audio',
            })
          }
          removeVideo={() =>
            setQuestionDialogData({
              title: $t('questionDialog.title.video-delete'),
              isVisible: true,
              action: 'remove-video',
            })
          }
          onDiscardEventClick={() =>
            setQuestionDialogData({
              title: $t('questionDialog.title.respondent-event-discard'),
              isVisible: true,
              action: 'discard-event',
            })
          }
          playAudio={playAudio}
          playVideo={playVideo}
          isTooltipVisible={isTooltipVisible}
          setIsTooltipVisible={(value) => setIsTooltipVisible(value)}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
        />
      )}
      <AudioRecorderComponent
        open={isAudioRecording}
        onRecordingComplete={onAudioRecordingComplete}
        closePlayer={() => setIsAudioRecording(false)}
      />
      <VideoRecorderComponent
        open={isVideoRecording}
        onRecordingComplete={handleOnVideoRecordingComplete}
        closePlayer={() => setIsVideoRecording(false)}
      />
      <CFQuestionDialog
        title={questionDialogData.title}
        confirmButtonTitle={$t('questionDialog.buttons.yes')}
        declineButtonTitle={$t('questionDialog.buttons.no')}
        isDialogOpen={questionDialogData.isVisible}
        handleClose={() =>
          setQuestionDialogData((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        confirm={questionDialogConfirmHandler}
      />
    </Container>
  );
};

export default CreateEventPage;
