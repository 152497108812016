import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import Notifier from 'containers/Notifier';
import Routes from './Routes';
import { fetchAuthenticatedUser, logout } from 'store/auth/actions';
import { makeSelectToken, makeSelectUser } from 'store/auth/selectors';
import AppBar from 'components/AppBar';
import styles from '../App/App.module.scss';
import { useTranslation } from 'react-i18next';
import SnackbarComponent from '../../components/Snackbar/SnackbarComponent';
import Footer from '../../components/Footer';
import { useHistory } from 'react-router-dom';
import { LOGIN, MAINTENANCE } from '../../routes';
import Loader from '../Loader';
import PausedProjectModal from '../../components/PausedProjectModal';
import { isPausedProjectModalOpenSelector } from '../../store/helper/selectors';
import { setIsPausedProjectModalOpen } from '../../store/helper/actions';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  // const maintainceMode = process.env.REACT_APP_MAINTENANCE_MODE;
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  console.log('API URL:', isMaintenanceMode);

  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const isPausedProjectModalOpen = useSelector(
    isPausedProjectModalOpenSelector()
  );

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const handleLanguageChange = useCallback(
    (val) => {
      i18n.changeLanguage(val);
    },
    [i18n]
  );

  const setUserLanguage = useCallback(() => {
    if (user?.selected_language) {
      i18n.changeLanguage(user?.selected_language);
    } else {
      i18n.changeLanguage('en');
    }
  }, [user?.selected_language]);

  useEffect(() => {
    if (isMaintenanceMode) {
      history.push(MAINTENANCE);
    } else {
      if (token) {
        dispatch(fetchAuthenticatedUser());
      } else {
        history.push(LOGIN);
      }
    }
  }, [token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUserLanguage();
  }, [setUserLanguage]);

  useEffect(() => {
    dispatch(setIsPausedProjectModalOpen(user?.project?.status === 'paused'));
  }, [user]);

  return (
    <HelmetProvider>
      <SnackbarProvider>
        <Helmet>
          <title>Lumanity Respondent</title>
        </Helmet>
        {token && !user ? (
          <Loader />
        ) : (
          <>
            {user?.terms_and_policy && (
              <AppBar
                onLanguageChange={handleLanguageChange}
                user={user}
                onLogout={handleLogout}
              />
            )}
            <div className={styles.container}>
              <Routes user={user} />
              <Loader />
            </div>
            {user?.terms_and_policy && <Footer />}
          </>
        )}
        <SnackbarComponent />
        <Notifier />
        <PausedProjectModal
          isOpen={isPausedProjectModalOpen}
          isJourneyFinished={user?.status === 'finished'}
          date={user?.project?.pausedDate}
        />
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
