export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const MAINTENANCE = '/maintenance';
export const USER_PROFILE = '/profile';
export const WELCOME_PAGE = '/welcome';
export const CREATE_EVENT_PAGE = '/create-event/:stepId';
export const EDIT_EVENT_PAGE = '/create-event/:stepId/:respondentEventId';
export const CREATE_ACCOUNT_PAGE = '/create-account';
export const EVENT_LIST_PAGE = '/events';
export const TERMS_OF_USE = '/terms-of-use';
